import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0,5,6,7];

export const dictionary = {
		"/(app)": [~13,[2]],
		"/(app)/admin": [~15,[2,4]],
		"/(app)/admin/customers": [~16,[2,4]],
		"/(app)/admin/customers/new": [~21,[2,4]],
		"/(app)/admin/customers/[straikerId]/edit": [~17,[2,4,5]],
		"/(app)/admin/customers/[straikerId]/users": [~18,[2,4,5]],
		"/(app)/admin/customers/[straikerId]/users/new": [~20,[2,4,5]],
		"/(app)/admin/customers/[straikerId]/users/[email]/edit": [~19,[2,4,5]],
		"/(app)/applications": [~22,[2]],
		"/(app)/applications/new": [27,[2]],
		"/(app)/applications/[id]": [~23,[2,6]],
		"/(app)/applications/[id]/detections": [24,[2,6]],
		"/(app)/applications/[id]/users": [~25,[2,6,7]],
		"/(app)/applications/[id]/users/[userId]": [~26,[2,6,7]],
		"/(app)/kitchen-sink": [28,[2,8]],
		"/(app)/kitchen-sink/badge": [29,[2,8]],
		"/(app)/kitchen-sink/button": [30,[2,8]],
		"/(app)/kitchen-sink/card": [31,[2,8]],
		"/(app)/kitchen-sink/hex-grid": [32,[2,8]],
		"/(app)/kitchen-sink/hexagon": [33,[2,8]],
		"/(app)/kitchen-sink/logo": [34,[2,8]],
		"/(app)/kitchen-sink/switch": [35,[2,8]],
		"/(app)/kitchen-sink/tag": [36,[2,8]],
		"/(unprotected)/login": [~43,[10,11]],
		"/(unprotected)/login/password": [~44,[10,11,12]],
		"/(unprotected)/login/password/forgot": [~45,[10,11,12]],
		"/(unprotected)/logout": [46,[10]],
		"/(app)/profile": [37,[2]],
		"/(app)/settings": [~38,[2,9]],
		"/(app)/settings/detections": [~39,[2,9]],
		"/(app)/settings/users": [~40,[2,9]],
		"/(app)/settings/users/new": [~42,[2,9]],
		"/(app)/settings/users/[email]/edit": [~41,[2,9]],
		"/(app)/[...catchall]": [~14,[2],[,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';